import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeroCTA from "../../components/hero/hero-cta"
import WindowPane from "../../components/windowpane/windowpane"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Savings from "../../components/savings/savings"

import desktopHero from "../../images/how-it-works/hero-desktop.png"
import mobileHero from "../../images/how-it-works/hero-mobile.png"
import numberIconDesktop from "../../images/how-it-works/3.1-Number_1_icon-01.png"
import numberIconMobile from "../../images/how-it-works/3.1-Number_1_icon-01.png"
import gradientOne from "../../images/clinical-trial-results/gradientOne.png"
import gradientTwo from "../../images/how-it-works/gradientTwo.svg"
import glassHeart from "../../images/benefits/crystal-heart.png"
import ctaBackground from "../../images/how-it-works/cta-background.png"
import laptop from "../../images/protect-against-another-heart-attack/laptop_q.png"
import blockColumnImageOne from "../../images/how-it-works/3.1-fish_Icon-01.png"
import blockColumnImageTwo from "../../images/how-it-works/block-column-image-2.png"
import savingsCard from "../../images/savings-card/savingsCard.png"
import windowBackgroundOne from "../../images/how-it-works/background-one.png"
import windowBackgroundTwo from "../../images/how-it-works/background-two.png"
import ladySitting from "../../images/how-it-works/lady-sitting.png"
import ladySittingMobile from "../../images/how-it-works/lady-sitting-mobile.png"
import leslieHeart from "../../images/how-it-works/leslie-heart-head.png"
import doctorHeartWoman from "../../images/how-it-works/doctor-heart-woman.png"
import doctorHeartMobile from "../../images/how-it-works/doctor-heart-mobile.png"
import clipboardIcon from "../../images/how-it-works/3.1-Clipboard_Icon-01.png"

export default () => (
  <Layout>
    <SEO
      title="How It Works"
      keywords="how does VASCEPA work, VASCEPA vs fish oil, VASCEPA and fish oil"
      description="Discover how VASCEPA® (icosapent ethyl) works and how it's different from fish oil supplements. Please see Indication and Important Safety Information."
    />
    <HeroCTA
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      additionalClassName=" background-white"
      headline={["The clear choice ", 
                  <span className="no-wrap">for reducing</span>,
                  " cardiovascular (CV) risk"
                ]}
      alt="Clear VASCEPA® (icosapent ethyl) capsule"
      content={[
        "Only VASCEPA has the FDA's stamp of approval for reducing CV risk when added to a statin.",
        <p className="fs-10 mt-5 hero-footer">Capsule is not actual size.</p>
      ]}
      url1="/about-vascepa/how-it-works#how-fish-oil"
    />

    <div className="row block mb-4">
      <div className="col-md-5 d-flex align-items-end order-sm-last p-0">
        <img
          className="img-fluid"
          src={leslieHeart}
          alt="Smiling man and woman"
        ></img>
      </div>
      <div className="col-md-7 whiteborderRight">
        <div 
          className="col-gutters px-1 pl-sm-4 pr-sm-5"
          style={{
            color: "#4D4D4D",
            fontSize: "1.125rem",
            margin: 0,
            paddingBottom: "55px",
            paddingTop: "55px",
          }}
        >
          <h2
            style={{
              color: "#1D355E",
              fontSize: "1.875rem",
              fontWeight: "700",
              lineHeight: "2.375rem",
              marginBottom: "24px"
            }}
          >
            Cardio protection. <br/> Proven by science.
          </h2>
          <p>In a landmark clinical trial involving more than 8,000 eligible patients, <strong>VASCEPA was proven and FDA-approved to reduce CV risk when added to a statin</strong>.</p>
          <p>VASCEPA is the only FDA-approved oral medication that can make this bold statement.</p>
          <p>Is there anything more important than heart protection? If you or someone you love has heart disease, or had experienced a previous heart attack or stroke, there is no time to waste. VASCEPA is proven to provide 25% additional heart protection when added to a statin.*</p>
          <p style={{ fontSize:"0.6875rem" }}><span style={{ marginLeft: "-6px" }}>*</span>In a clinical study, patients treated with VASCEPA and a statin had fewer CV events (17.2%) compared to those who took placebo (22%).</p>
        </div>
      </div>
    </div>


    <WindowPane
      mobileImage={numberIconMobile}
      desktopImage={numberIconDesktop}
      alt="Number 1 icon"
      whiteBorderOne="whiteborderRight"
      backgroundOne={gradientOne}
      colorOne=""
      headlineOne="What’s in VASCEPA?"
      contentTwo="FDA-approved VASCEPA is made up of one active ingredient: icosapent ethyl (IPE), which is an innovative form of eicosapentaenoic acid (EPA)"
      colorTwo="#1D355E"
      contentThree="If “EPA” sounds familiar, that’s because it’s an omega-3 fatty acid. IPE is the only active ingredient in VASCEPA and is considered the reason behind the significant CV benefits VASCEPA can deliver."
      backgroundColorTwo="#E7E8EA"
      mobileClass="no-background"
    />

    <div style={{ minHeight: "400px" }} className="block row">
      <div className="col-md-7 block-content whiteborderRight background-gradient-two center-items">
        {/* <img class="background-gradient" src={gradientTwo}></img> */}
        <div className="">
          <h3
            className="font-margins"
            style={{
              fontSize: "24px",
              color: "#1D355E",
              lineHeight: "30px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              marginTop: "20px;",
            }}
          >
            How VASCEPA reduces CV risk
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "18px",
              color: "#4D4D4D",
              lineHeight: "22px",
              fontWeight: "500",
              zIndex: "1",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            VASCEPA is the first and only FDA-approved medication to lower CV risk
            in addition to other medications, such as statins, in patients with 
            high triglycerides (&ge;150 mg/dL) and heart disease or diabetes with other CV risk factors. 
            The exact science of how VASCEPA works in your body is not fully understood, but years 
            of clinical testing have led to proven results. Ask your doctor about heart protection with VASCEPA.
          </p>
        </div>
      </div>
      <div
        style={{ padding: "0px", minHeight: "300px" }}
        className="col-md-5 block-image "
      >
        <img
          className="desktop-height-auto"
          style={{
            // height: "100%",
            position: "absolute",
            left: "-9999px",
            right: "-9999px",
            margin: "auto",
          }}
          src={glassHeart}
          alt="Vascepa Glass heart"
        ></img>
      </div>
    </div>

    <RightCta
      ctaBackground={ctaBackground}
      alt="Laptop icon"
      colOne="7"
      colTwo="5"
      colThree=""
      whiteborder={"width90"}
      image={laptop}
      mobileImage={laptop}
      headline="Are you at greater risk than you think?"
      cta={["Take the quiz ", <span aria-hidden="true">&gt;</span>]}
      url="/cardiovascular-risk/quiz"
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      whiteborder="transparentBorderRight"
    />
{/* 
    <div
      style={{ backgroundImage: windowBackgroundOne }}
      className="block-banner-white"
    >
      <h3 style={{fontSize:"2rem", paddingLeft: "20px"}}>
      VASCEPA helps protect you from a life-threatening event like a heart attack <span className="no-wrap">or stroke</span>
      </h3>

      <p style={{ fontSize: "18px", paddingLeft: "20px" }}>
      For many people, the risk of debilitation from stroke is feared more than death from a heart attack. 
      </p>

      <p style={{ fontSize: "18px", paddingLeft: "20px" }}>
      VASCEPA is the first and only FDA-approved medicine to lower risk of events such as stroke, when used with statins, in patients with high triglycerides and heart disease or diabetes with other CV risk factors.
      </p>
    </div> */}



    <div id="how-fish-oil" className="block-banner-simple">
      <h2>A closer look at fish oil supplements</h2>
      <p>
      When it comes to protecting your heart, learn how fish oil supplements are different from VASCEPA.
      </p>
    </div>

    <div className="block gradient-alt2 row">
      <div className="col-md-6 block-column block-content">
        <img alt="" className="block-column-image" src={blockColumnImageOne} />
        <h3 className="bullet-header" style={{color:"#1d355e"}}>Fish oil supplements</h3>
        <ul style={{ fontSize: "18px" }}>
          <li>
          After reviewing decades of research, the FDA found fish oil supplements
          do not meet the standard required for a significant scientific health claim
          </li>
          <li>
            Contain a mixture of ingredients that may be harmful to heart
            health, and may have a pronounced fishy smell or require smell
            masking to hide the signs of deterioration/oxidation (rotting)
          </li>
          <li>Not intended or approved to treat heart disease</li>
          <li>
            The American Heart Association advises against self-medicating with
            supplements
          </li>
          <li>
            The most expensive product is one that doesn't treat the intended
            condition
          </li>
        </ul>
      </div>
      <div className="col-md-6 block-column block-column-border-left h100 block-column-mobile-border-top block-content">
        <img
          className="block-column-image m-y-10-d"
          alt=""
          src={blockColumnImageTwo}
        />
        <h3 className="bullet-header" style={{color:"#1d355e"}}>VASCEPA</h3>
        <ul style={{ fontSize: "18px" }}>
          <li>
            Proven CV benefit in the landmark REDUCE-IT<sup>&reg;</sup> trial{" "}
          </li>
          <li>
            Made up of one active ingredient: IPE (icosapent ethyl), an
            innovative form of EPA
          </li>
          <li>
            FDA approved to significantly reduce CV risk in patients taking
            statins. If you cannot take statins, talk to your doctor about
            whether or not VASCEPA can help you
          </li>
          <li>
            Recognized by medical societies to lower CV risk in patients with heart disease or diabetes and risk factors{" "}
          </li>
        </ul>
      </div>
      <div className="col-md-12 block-column block-content p-t-0-desk">
        <p style={{
          color: "#1d355e",
          paddingLeft: "20px",
          paddingBottom: "10px",
          fontSize:'1.5rem'
          }} className="blue">
          When it comes to protecting your heart, the choice is clear:
          FDA-approved VASCEPA
        </p>
      </div>
    </div>

    <div className="row block mb-4">
      <div className="col-md-5 d-flex align-items-end p-0 whiteborderRight">
        <picture>
          <source media="(max-width: 576px)" srcSet={doctorHeartWoman}></source>
          <img
            className="img-fluid"
            src={doctorHeartWoman}
            alt="Doctor smiling"
          ></img>
        </picture>
      </div>
      <div className="col-md-7 saturated-gradient-background content-center">
        <div 
          
          style={{
            fontSize: "1.5rem",
            fontWeight: "500",
            lineHeight: "1.875rem"
          }}
        >
          <h2
            style={{
              fontSize: "1.875rem",
              fontWeight: "700",
              lineHeight: "2.375rem",
              marginBottom: "24px"
            }}
          >
            Talk to your doctor about CV risk reduction
          </h2>
          <p>As you discuss with your doctor or pharmacist if VASCEPA may be right for you to reduce your CV risk, the subject of generic alternatives to VASCEPA may come up.</p>
          <p>Between insurance coverage, Medicare Part D, and the VASCEPA Savings Card (see offer below), you may find that VASCEPA is actually cheaper than a generic. If you have any questions or concerns about cost, make sure to contact your insurance company.</p>
        </div>
      </div>
    </div>
    
    <LeftCta
      colOne="7"
      colTwo="5"
      whiteborder="whiteborder"
      image={savingsCard}
      mobileImage={savingsCard}
      alt="VASCEPA® (icosapent ethyl) savings card"
      background={gradientTwo}
      headline="Looking for ways to save on VASCEPA?"
      cta={["Get the VASCEPA Savings Card",<span aria-hidden="true">&nbsp;&gt;</span>]}
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      external="true"
      url="/getting-started/savings-card"
      imgSM="savings-card"
      contentSubClass="left "
      ctaSubClass="left"
      imgBackground="savings-card"
      mobileImageClass="mobile-savings-card"
    />

      <LeftCta
        colOne="7"
        colTwo="5"
        whiteborder="whiteborder"
        image={clipboardIcon}
        mobileImage={clipboardIcon}
        alt="Clipboard icon"
        background={gradientTwo}
        headline="VASCEPA is FDA-approved to help protect you from a life-threatening heart attack or stroke. Is VASCEPA right for you?"
        cta={["Get questions for your doctor ",<span aria-hidden="true">&gt;</span>]}
        headlineColor = "#1D355E"
        ctaColor = "#1D355E"
        external="true"
        url="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
        contentSubClass="left"
        ctaSubClass="left"
    /> 
  </Layout>
)
